import React, { useEffect, useRef } from "react";
import * as typeformEmbed from "@typeform/embed";
import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      "https://form.typeform.com/to/dRTVtJei",
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 50,
      }
    );
  }, [typeformRef]);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style2",
        }}
      >
        <div className="min-height-100vh d-flex align-items-center pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div
                  className="mb-10 mb-lg-18 text-center"
                  data-aos="fade-up"
                  data-aos-duration={300}
                  data-aos-once="true"
                >
                  <h2 className="font-size-11 mb-7">Contacto</h2>
                  <p className="font-size-7 mb-0">
                    Envíanos un mensaje y te contactaremos dentro de 24 horas
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                {/* contact details */}
                <div
                  className="top-contact-info bg-default-1 max-w-540 mx-auto py-10 px-13 rounded-10"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                >
                  <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="border-md-right border-cornflower-blue">
                        <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                          Llámanos/Whatsapp
                        </h4>
                        <a
                          className="font-size-6 font-weight-bold heading-default-color"
                          href="https://api.whatsapp.com/send?phone=56942236206&text=Hola!%20Escribo%20para%20agendar%20un%20test%20PCR%2Fant%C3%ADgeno"
                        >
                          +56 9 42236206
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="pl-1 pr-3">
                        <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                          Mándanos un mail
                        </h4>
                        <a
                          className="font-size-6 font-weight-bold heading-default-color"
                          href="mailto:contacto@midiagnostico.cl"
                        >
                          contacto@midiagnostico.cl
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  ref={typeformRef}
                  style={{ height: "50vh", width: "100%" }}
                ></div>
                {/* <div
                  class="typeform-widget"
                  data-url="https://form.typeform.com/to/dRTVtJei?typeform-medium=embed-snippet"
                  style="width: 100%; height: 500px;"
                ></div> */}
              </div>
            </div>
            {/* asdasd */}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
